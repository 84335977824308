// extracted by mini-css-extract-plugin
export var socialBlock = "c0_wX";
export var socialBlock__icon = "c0_w2";
export var socialBlock__iconWrapper = "c0_w1";
export var socialBlock__icon_facebook = "c0_w3";
export var socialBlock__icon_instagram = "c0_w6";
export var socialBlock__icon_linkedin = "c0_w4";
export var socialBlock__icon_telegram = "c0_w7";
export var socialBlock__icon_tiktok = "c0_w8";
export var socialBlock__icon_twitter = "c0_w5";
export var socialBlock__iconsWrapper = "c0_w0";
export var socialBlock__sprite = "c0_wZ";
export var socialBlock__title = "c0_wY";