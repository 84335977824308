// extracted by mini-css-extract-plugin
export var recentPost = "bW_m7";
export var recentPost__button = "bW_np";
export var recentPost__postAuthor = "bW_nj";
export var recentPost__postAuthorImage = "bW_nh";
export var recentPost__postAuthorName = "bW_nk";
export var recentPost__postCard = "bW_m8";
export var recentPost__postCategory = "bW_nc";
export var recentPost__postDate = "bW_nn";
export var recentPost__postDescriprion = "bW_nf";
export var recentPost__postImage = "bW_nb";
export var recentPost__postInfo = "bW_nl";
export var recentPost__postInfoWrapper = "bW_ng";
export var recentPost__postLink = "bW_m9";
export var recentPost__postStats = "bW_nm";
export var recentPost__postTitle = "bW_nd";
export var recentPost__title = "bW_nq";